.background {
    background: #FCFCFE;
    padding-bottom: 50px;
}

.wrapper {
    max-width: 1200px;
    padding: 0 16px;
    margin: 0 auto;
}

.statusCircle {
    width: 53px;
    height: 53px;
    background: #EDEDED;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3px;
}

.statusActive {
    background: #2DCC70;
}

.statusWrapper {
    display: flex;
    align-items: center;
    margin-right: 120px;
}

.statusWrapperHistory {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.step {
    display: flex;
    align-items: center;
    justify-content: center;
}

.line {
    height: 1px;
    width: 100%;
    max-width: 957px;
    background: #EDEDED;
    margin-bottom: 30px;
}

.productWrapper {
    display: flex;
    margin-bottom: 15px;
    width: 100%;
    max-width: 335px;
}

.productWrapperHistory {
    display: flex;
    margin-bottom: 15px;
    max-width: 528px;
    width: 100%;
}

.productWrapper > img {
    width: 52px;
    height: 52px;
    border-radius: 6px;
    margin-right: 11px;
}

.productWrapperHistory > img {
    width: 52px;
    height: 52px;
    border-radius: 6px;
    margin-right: 11px;
}

.productName {
    width: 150px;
    display: flex;
    flex-direction: column;
    margin-right: 40px;
}

.priceWrapper {
    width: 100%;
    max-width: 335px;
}

.priceWrapperHistory {
    width: 100%;
    max-width: 528px;
}

.priceRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.button {
    width: 306px;
    height: 50px;
    margin-left: 20px;
    padding-bottom: 100px;
}

.buttonHistory {
    width: 528px;
    margin-bottom: 45px;
    text-align: right;
    display: flex;
    justify-content: flex-end;
}

.buttonHistory > button {
    width: 254px;
    height: 35px;
}

.courier {
    width: 345px;
    height: 80px;
    border-radius: 24px;
    background: #EDEDED;
    display: flex;
    align-items: center;
    padding: 19px 16px;
}

.courier > img {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    margin-right: 14px;
}

.courierInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 45px;
}

.orderStatus {
    display: flex;
}

.orderStatusHistory {
    display: flex;
    flex-direction: column;
}

@media (max-width: 420px) {

    .wrapper {
        max-width: 335px;
        padding: 0 16px;
    }

    .statusCircle {
        width: 53px;
        height: 53px;
        background: #EDEDED;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 3px;
    }

    .statusActive {
        background: #2DCC70;
    }


    .statusWrapper {
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 335px;
        margin: 0 auto 20px;
    }

    .courier {
        width: 100%;
        height: 92px;
        border-radius: 24px;
        background: #EDEDED;
        display: flex;
        align-items: center;
        padding: 18px 15px;
        margin-left: 0;
        justify-content: space-between;
        margin-bottom: 40px;
    }

    .courierImg {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .courierImg > img {
        width: 42px;
        height: 42px;
        border-radius: 50%;
        margin-right: 0;
    }

    .courierInfo > img {
        width: 42px;
        height: 42px;
        border-radius: 50%;
        margin-right: 26px;
    }

    .courierInfo {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 45px;
    }

    .call > button {
        width: 144px;
        height: 42px;
    }

    .orderStatus {
        display: flex;
        flex-direction: column;
    }

    .step {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .line {
        height: 1px;
        width: 100%;
        max-width: 957px;
        background: #EDEDED;
        margin-bottom: 30px;
    }

    .productWrapper {
        display: flex;
        margin-bottom: 15px;
        justify-content: space-between;
    }

    .productWrapper img {
        width: 52px;
        height: 52px;
        border-radius: 6px;
        margin-right: 11px;
    }

    .productName {
        width: 150px;
        display: flex;
        flex-direction: column;
        margin-right: 40px;
    }

    .priceWrapper {
        width: 100%;
    }

    .priceRow {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
    }

    .button {
        width: 335px;
        height: 50px;
        margin-left: 0;
    }
}
