.container {
    max-width: 1200px;
    width: 100%;
    margin: 45px auto 0;
    padding: 0 16px;

}

.green {
    background: #2DCC70;
    min-height: 47px;
    width: 100%;
    max-width: 662px;
    padding: 15px;
    margin-bottom: 33px;

}

.calc {
    background: #EDEDED;
    padding: 22px 31px 25px 25px;
    border-radius: 8px;
    width: 100%;
    max-width: 817px;
    margin-bottom: 45px;

}

.inputSearch {
    height: 47px;
    padding: 10px 46px 10px 20px;
    width: 100%;
    text-overflow: ellipsis;
    max-width: 725px;
    color: #213140;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    font-family: 'HelveticaNeueCyr-Bold', sans-serif;
    text-overflow: ellipsis;
}

.suggestionsList {
    flex: 1;
    overflow-y: auto;
    background: #fff;
    -webkit-box-shadow: 0px 5px 10px -2px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 5px 10px -2px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 5px 10px -2px rgba(34, 60, 80, 0.2);
    position: absolute;
    z-index: 1;
    top: 49px;
    right: 0;
}

.tick {
    position: absolute;
    top: 10px;
    right: 16px;
}

.suggestionItem {
    padding: 16px 20px;
    display: flex;
    align-items: center;
    border-bottom: #ededed 1px solid;
    cursor: pointer;
}

.suggestionItem:hover {
    background: #ededed;
}

.inputSearch::placeholder {
    font-size: 18px;
    color: #BDBDBD;
    line-height: 24px;
    font-family: 'HelveticaNeueCyr-Bold', sans-serif;
    width: fit-content;
}

.inputSearch:focus::-webkit-input-placeholder {
    color: transparent;
    border-bottom: transparent;
}

.inputSearch:focus::-moz-placeholder {
    color: transparent;
    border-bottom: transparent;
}

.inputSearch:focus:-moz-placeholder {
    color: transparent;
    border-bottom: transparent;
}

.inputSearch:focus:-ms-input-placeholder {
    color: transparent;
    border-bottom: transparent;
}

.suggestMob {
    width: calc(100% - 26px);
}

.suggestDesc {
    width: calc(100% - 38px);
}


.info {
    display: flex;
    flex-direction: column;
    max-width: 485px;
    width: 100%;
    margin-bottom: 23px;

}

.payment {
    margin-bottom: 25px;
}

.li {
    padding-left: 22px;
    position: relative;
}

.li:before {
    content: '';
    display: inline-block;
    background: #000;
    position: absolute;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    top: 6px;
    left: 8px;
}

.hr {
    max-width: 892px;
    width: 100%;
    display: block;
    background: #EDEDED;
    height: 1px;
    border-radius: 2px;
    margin-bottom: 38px;
}


.deliveryWrapper {
    display: flex;
    margin-left: 50px;
    flex-wrap: wrap;
}

@media (max-width: 788px) {
    .deliveryWrapper {
        flex-direction: column;
    }
}

.verticalLine {
    max-height: 182px;
    height: 100vh;
    background: #C4C4C4;
    width: 1px;
    display: block;
    margin-right: 28px;
    margin-left: 67px;

}

.horizontalLine {
    max-width: 286px;
    width: 100%;
    background: #C4C4C4;
    height: 1px;
    display: block;
    margin-top: 25px;
    margin-bottom: 18px;
}

.aboutPrice {
    display: flex;
    justify-content: space-between;
    max-width: 286px;
    width: 100%;

}

.sum, .sumPrice {
    display: flex;
    flex-direction: column;
    /*gap: 20px;*/
}

.sum > span, .sumPrice > span {
    margin-bottom: 20px;
}

.sumPrice {
    align-items: flex-end;
}

.intervalWrapper {
    display: flex;
    justify-content: space-between;
    max-width: 288px;
    width: 100%;
    margin-bottom: 10px;

}

@media (max-width: 475px) {
    .container {
        max-width: 475px;
        padding: 0 20px;
    }

    .green {
        padding: 20px;
        margin-bottom: 24px;
    }

    .calc {
        padding: 19px 17px 25px 3px;
        max-width: 475px;
        margin-bottom: 38px;
    }

    .inputSearch {
        padding: 10px 26px 10px 8px;
        max-width: 475px;
        font-size: 14px;
        font-family: 'HelveticaNeueCyr-Bold', sans-serif;
    }

    .inputSearch::placeholder {
        font-size: 14px;
        font-family: 'HelveticaNeueCyr-Roman', sans-serif;
    }

    .deliveryWrapper {
        margin-left: 30px;
    }

    input::placeholder {
        font-size: 16px;
        width: fit-content;
    }


    .info {
        display: flex;
        flex-direction: column;
        max-width: 335px;
        width: 100%;
        margin-bottom: 18px;

    }

    .payment {
        margin-bottom: 25px;
    }

    .tick {
        top: 10px;
        right: 8px;
    }

    .tick img {
        height: 14px;
        width: 14px;
    }

}
