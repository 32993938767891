.wrapper {
    padding: 60px 20px 20px 20px;
    background-color: #FCFCFE;
}

.user {
    display: flex;
    /*gap: 25px;*/
    align-items: center;
    margin-bottom: 45px;
}

.user > * {
    margin-right: 25px;
}

.user > *:last-child {
    margin-right: 0;
}

.category {
    height: 85px;
    background: #fff;
    width: 85px;
    border-radius: 12px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 8px 3px 0px 6px;
    overflow: hidden;
    margin: 4.5px;
}

.categoryIcon > img {
    width: 40px;
    height: 40px;
    margin-left: -4px;
    margin-top: -8px;
    object-fit: contain;
}

.categoryName {
    overflow: hidden;
    width: 80px;
    max-height: 2.4rem;
    word-break: keep-all;
    margin-bottom: 5px;
    text-overflow: ellipsis;
}

.categoryName a{
    color: #213140;
}

.categoryName > span {
    max-width: 80px;
}

.categories {
    display: flex;
    margin: -4.5px;
    flex-wrap: wrap;
    margin-bottom: 35px;
}

.info {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.info span, .info a {
    margin-bottom: 25px;
}

.contacts {
    display: flex;
    flex-direction: column;
}
