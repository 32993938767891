.wrapper {
    margin: 40px 0 0 50px;
}

.bonusInfo, .promoInfo {
    display: flex;
    flex-direction: column;
}

.anketa {
    width: 270px;
    background: #eeeeee;
    border-radius: 4px;
    padding: 11px;
    margin-bottom: 20px;
}

.anketaInfo {
    display: flex;
    gap: 6px;
    align-items: flex-start;
    margin-bottom: 15px;
}

.anketaRef {
    width: fit-content;
    border-bottom: 1px solid #383838;
    cursor: pointer;
    margin-left: 25px;
}

.promoForFriends {
    display: flex;
    /*gap: 15px;*/
    margin-bottom: 25px;
}

.promoForFriends > input {
    background: #EEEEEE;
    width: 267px;
    height: 60px;
    border-radius: 4px;
    padding: 16px;
    font-family: 'HelveticaNeueCyr-Roman', sans-serif;
    font-size: 18px;
    margin-right: 15px;
}

.copyCircle {
    width: 53px;
    height: 53px;
    background: #213140;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modalWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (max-width: 650px) {
    .wrapper {
        margin: 0;
    }

    .bonusInfo, .promoInfo {
        display: flex;
        flex-direction: column;
    }

    .promoForFriends {
        display: flex;
        /*gap: 15px;*/
        margin-bottom: 40px;
    }

    .promoForFriends > input {
        background: #EEEEEE;
        width: 267px;
        height: 60px;
        border-radius: 4px;
        padding: 16px;
        font-family: 'HelveticaNeueCyr-Roman', sans-serif;
        font-size: 18px;
    }

    .copyCircle {
        width: 53px;
        height: 53px;
        background: #213140;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .promoModal {
        background: #EDEDED;
        border-radius: 50%;
        width: 26px;
        height: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 15px;
    }

    .circle {
        background: #F5F5F5;
        border-radius: 50%;
        width: 26px;
        height: 26px;
        position: absolute;
        top: 17px;
        right: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .circle > img {
        width: 14px;
        height: 14px;
    }

    .modalWrapper {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        padding: 50px 11px 29px;
    }
}
