.popupWrapper {
    position: relative;
    max-width: 383px;
    width: 100%;
}

.input {
    background-color: rgba(206, 206, 206, 0.38);
    height: 50px;
    padding-left: 13px;
    padding-right: 90px;
    border-radius: 8px;
    border: none;
    outline: none;
    width: 100%;
    max-width: 383px;
    /*    background-image: url("../assets/images/search.svg");
        background-repeat: no-repeat;
        background-position: right 5% bottom 50%;*/
    flex: 1;
}

.buttons {
    position: absolute;
    top: 6px;
    right: 18px;
    display: flex;
    align-items: center;
}

.popup {
    position: absolute;
    width: 100%;
    top: 52px;
    background: #fff;
    border-radius: 8px;
    -webkit-box-shadow: 0 5px 10px -2px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0 5px 10px -2px rgba(34, 60, 80, 0.2);
    box-shadow: 0 5px 10px -2px rgba(34, 60, 80, 0.2);
}

.popupItem {
    padding: 4px 13px;
    color: #213140;
    cursor: pointer;

}

.popupItem:hover, .popupItem:focus {
    background: #ededed;
}


.popupContainer {
    background-color: #FFF;
    opacity: 1 !important;
    width: 100%;
    min-height: 200px;
    position: absolute;
    top: 68px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
    border-radius: 4px 4px 24px 24px;
    z-index: 1000;
}


.popupContent {
    display: flex;
    align-items: stretch;
    padding: 20px 0;
    flex: 1;
    height: 100%;
    max-height: 100%;
}

.searchSelector {
    width: 330px;
    border-right: solid 1px #c4c4c4;
}

.resultsContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-self: stretch;
    max-height: 60vh;
    overflow-y: scroll;
}

.searchResults {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(163px, 1fr));
    grid-gap: 20px;
    padding: 40px 40px 0;
}

.popupBottomLine {
    background: #fff;
    display: flex;
    justify-content: flex-end;
    padding: 30px 52px;
}

.autoCompleteSuggestion {
    padding: 9px 23px;
    cursor: pointer;
}

.autoCompleteSuggestion:hover {
    background-color: #f7f7f7;
}

.autoCompleteSuggestion:hover span {
    color: #2DCC70 !important;
}


.seeAll {
    background-color: #f0f0f0;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 185px;
    border-radius: 8px;
}

.seeAll > span {
    text-decoration: underline;
}

.productCard {
    min-width: 120px;
    max-width: 320px;
    background-color: #fff;
    border-radius: 6px;
    cursor: pointer;
    height: max-content;
    position: relative;
}

.productImage {
    background-color: #dadada;
    width: 100%;
    aspect-ratio: 1;
    overflow: initial;
    position: relative;
    border-radius: 6px;

}

.productImage > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
}

.productImage::before {
    float: left;
    padding-top: 100%;
    content: '';
}

.productImage::after {
    display: block;
    content: '';
    clear: both;
}

.productControllers {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.productName > div {
    width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
}

.bottomBlock {
    display: flex;
    justify-content: space-between;
}

.button {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #213140;
}


.priceBlock {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 12px;
}

.discount {
    position: relative;
    text-decoration: none;
    width: fit-content;
}

.discount:before {
    content: '';
    border-bottom: 2px solid #FF5252;
    position: absolute;
    border-radius: 2px;
    top: 3px;
    width: 115%;
    height: 40%;
    left: -3px;
    z-index: 1;
}

.percents {
    margin-left: 6px;
    background: #2DCC70;
    padding: 2px 4px;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
}

.amountSelectorWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.amountSelector {
    max-width: 187px;
    background-color: rgba(255, 255, 255, .5);
    width: 90%;
    height: 55px;
    border-radius: 17px;
    backdrop-filter: blur(10px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;
    z-index: 2;
}

.selectorButton {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 0 14px;
    height: 100%;
}

.quantityIsLimited {
    background: #fff;
    border-radius: 4px;
    width: 90%;
    margin: 6px auto 0;
    min-height: 25px;
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    font-family: 'HelveticaNeueCyr-Bold', sans-serif;
    text-align: center;
    position: absolute;
    left: 5%;
    bottom: 55px;
    display: grid;
    place-items: center;
}

.left {
    justify-content: flex-start;
}

.right {
    justify-content: flex-end;
}

.notifyMe {
    background: #2DCC70;
    position: absolute;
    cursor: pointer;
    bottom: 0;
    height: 0;
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    opacity: 0;
    transition-duration: .3s;

}

.productCard:hover .notifyMe {
    opacity: 1;
    height: 50px;
}

.productCard:hover .imgOut {
    filter: grayscale(0%);
}

.productCard:hover .mailMe {
    background: #2DCC70 !important;
}

.mailMe {
    background: #c4c4c4 !important;
    transition-duration: .3s !important;
}

.imgOut {
    filter: grayscale(100%);
    transition-duration: .3s;
}

.freeze {
    position: absolute;
    top: 3%;
    left: 3%;
    width: 44px;
    z-index: 5;
}

.badgesWrapper {
    position: absolute;
    top: -13px;
    left: 1px;
    z-index: 5;
    height: fit-content;
}

.badge img{
    height: 34px;
    margin-bottom: 6px;
}



@media (max-width: 1200px) {
    .quantityIsLimited {
        bottom: 10%;
    }
}


@media (max-width: 992px) {
    .popupContainer {
        position: absolute;
        margin-top: -25px;
        left: 0;
    }

    .badge img {
        height: 26px;
        margin-bottom: 4px;
    }

    .popupContent {
        flex-direction: column;
        max-height: max-content;
        height: max-content;
    }

    .searchSelector {
        width: 100%;
        border-bottom: solid 1px #c4c4c4;
        padding-bottom: 20px;
    }

    .resultsContainer {
        display: flex;
        flex-direction: column;
        align-self: stretch;
        max-height: 100%;
        overflow-y: hidden;
    }

    .searchResults {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
        grid-gap: 20px;
        padding: 20px 20px 0;
    }

    .quantityIsLimited {
        line-height: 14px;
        bottom: 5%;
        height: 42px;
    }
}
