.wrapper {
    background: #213140;
    padding-top: 32px;
    padding-bottom: 25px;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}

.container > * {
    margin-right: 25px;
}

.container > *:last-child {
    margin-right: 0;
}

.logo {
    display: flex;
    flex-direction: column;

}

.logoFooterMobile{
    display: none;
}

.company {
    margin-bottom: 24px;
}

.companyHeader, .infoHeader, .personalHeader, .orderHeader {
    margin-bottom: 8px;
}

.personalCards, .orderSupport, .orderWhatsapp{
    display: flex;
    align-items: flex-start;
}



.social {
    display: flex;
}

.social a {
    margin-right: 8px;
}

/* 1200px, 992px, 768px, 576px */

@media (max-width: 1200px) {
    .container {
        max-width: 992px;
        padding: 0 15px;
    }
    .logo {
        display: none;
    }
    .social {
        display: none;
    }
}

@media (max-width: 768px) {
    .container {
        max-width: 320px;
        display: grid;
        grid-template-columns: 320px;
        position: relative;
    }

    .order {
        grid-row-start: 1;
        grid-row-end: 2;
        width: 100%;
        padding:0 15px 24px;
        margin:0 -15px;
        border-bottom: #909090 1px solid;
        margin-bottom: 24px;
    }

    .personalAccount {
        display: none;
    }

    .social {
        display: flex;
        justify-content: center;
        margin-top: 24px;
    }

    .logoFooterMobile{
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        top: 182px;
        right: 0;

    }

}
