.wrapper {
    padding: 41px 0 30px;
    position: relative;
}

.container {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 335px;
    margin: 0 auto;
    /*    padding: 68px 20px 20px;*/

    justify-content: space-between;
}

.topLabel {
    text-align: center;
    margin-bottom: 9px;
    height: 65px;
    align-self: center;
    display: grid;
    place-items: center;
}

.bottomLabel {
    font-family: 'HelveticaNeueCyr-Bold', sans-serif;
    font-size: 18px;
    font-weight: 700;
    color: #213140;
    line-height: 20px;
    text-align: center;
    margin-bottom: 42px;
    align-self: center;
    display: grid;
    place-items: center;
}

.enterPhoneAgain {
    align-self: center;
    border-bottom-width: 1px;
    border-bottom-color: #BDBDBD;
}

.error {
    text-align: center;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
}

.cell {
    display: flex;
    width: 40px;
    height: 50px;
    align-items: center;
    justify-content: center;
    margin: 0 8px;
    position: relative;
}

.codeFieldRoot {
    display: flex;
    justify-content: center;
}

.dot {
    background-color: #bdbdbd;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    transition-duration: .3s;
    transform: scale(1);
    animation: none;
}

.caretBlink {
    animation: jop 1s infinite;
}

.caret {
    background-color: #c4c4c4;
    width: 2px;
    height: 49px;
    position: absolute;
    right: 32px;
    opacity: 0;
}

.invisibleInput {
    opacity: 0;
    width: 10px;
    height: 10px;
    position: fixed;
    top: -20px;
}

.input {
    background-color: #f7f7f7;
    height: 50px;
    padding: 0 30px;
    border-radius: 8px;
    border: none;
    outline: none;
    width: 100%;
    max-width: 335px;
    align-self: center;
    margin-bottom: 26px;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #bdbdbd;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #bdbdbd;
}

::-ms-input-placeholder { /* Microsoft Edge */
    color: #bdbdbd;
}

@keyframes jop {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}


@media (max-width: 767px) {
    .wrapper {
        padding: 20px 15px 30px;
        position: relative;
    }

    .topLabel {
        margin-bottom: 13px;
        max-width: 259px;
    }

    .bottomLabel {
        margin-bottom: 35px;
        align-self: center;
        display: grid;
        place-items: center;
        font-size: 18px;
        font-weight: 550;
    }

    .enterPhoneAgain {
        align-self: center;
        border-bottom-width: 1px;
        border-bottom-color: #BDBDBD;
    }

    .error {
        margin-bottom: 10px;
    }

    .cell {
        display: flex;
        width: 40px;
        height: 50px;
        align-items: center;
        justify-content: center;
        margin: 0 8px;
    }

    .codeFieldRoot {
        display: flex;
        justify-content: center;
    }

}