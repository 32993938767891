.wrapper {
    background: #fcfcfe;
    padding-bottom: 100px;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
}

.points {
    background: #EDEDED;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 27px;
    width: 56px;
    padding: 0 2px;
    text-align: center;
    margin-right: 24px;

}

.addressContainer {
    position: relative;
}

.addressInputNew {
    background-color: #fcfcfe;
    border: 1px solid #e0e0e0;
    border-radius: 3px;
    height: 43px;
    padding-left: 16px;
    font-family: 'HelveticaNeueCyr-Roman', sans-serif;
    width: 80vw;
    min-width: 100px;
    max-width: 564px;
}
.suggestionItemNew {
    padding: 10px 0;
    margin: 0 16px;
    border-bottom: 1px solid #e0e0e0;
}

.suggestionItemNew:last-child {
    border-bottom: 1px solid transparent;
}

.addressDropDown {
    border-bottom: 1px solid #e0e0e0;
    border-left: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
    border-radius: 0 0 3px 3px;
    position: absolute;
    background-color: #fff;
    top: 43px;
    width: 100%;
}

.addressError {
    position: absolute;
    top: -22px;
    right: 0;
}


@media (max-width: 768px) {

    .wrapper {
        background: #fcfcfe;
        padding-bottom: 70px;
    }

    .container {
        max-width: 768px;
    }
}

.header {
    padding: 30px 20px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 45px;
}

@media (max-width: 768px) {
    .header {
        padding: 30px 20px 25px;
        margin-bottom: 22px;
    }

    .logo > img {
        width: 127px;
        height: 41px;
    }
}

.contacts {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.title {
    margin-bottom: 21px;
    margin-left: 25px;
}

@media (max-width: 768px) {
    .title {
        max-width: 335px;
        /*margin: 0 auto 25px;*/
    }
}

.infoCard {
    background: #fff;
    max-width: 740px;
    min-height: 583px;
    padding: 50px 25px 25px 25px;
    margin-right: 85px;
}

.userInfo {
    background: #fff;
    width: 375px;
    height: 233px;
    padding: 30px 20px;
    margin-bottom: 15px;
}

@media (max-width: 768px) {
    .infoCard {
        background: #fcfcfe;
        width: 375px;
        min-height: 583px;
        padding: 0;
        margin-right: 0;
    }
}

.name {
    margin-bottom: 42px;
    max-width: 334px;
}

.name > input, .email > input {
    padding-bottom: 5px;
    border-bottom: 1px solid #EDEDED;
    font-size: 20px;
    font-weight: 900;
    line-height: 16px;
    min-width: 334px;
}

.addressInput > input {
    padding-bottom: 5px;
    border-bottom: 1px solid #EDEDED;
    font-size: 20px;
    line-height: 16px;
    min-width: 301px;
    font-family: 'HelveticaNeueCyr-Medium', sans-serif;
}

.geoInput {
    width: 100%;
}

.addressInput {
    display: flex;
    position: relative;
    align-items: center;
}

.addressModal {
    padding: 16px;
    display: flex;
    flex-direction: column;
    width: 100vw;
    max-width: 600px;
    height: 100vh;
    max-height: 900px;

}
.suggestionsList {
    flex: 1;
    overflow-y: auto;
    padding-top: 24px;
}

.inputRow {
    display: flex;
    margin-top: 24px;
    position: relative;
    align-items: center;
    gap: 4px
}

.gradient {
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 35%, rgba(255,255,255,0.542454481792717) 100%);
    /*background: red;*/
    width: 100%;
    height: 24px;
    position: absolute;
    top: 100%
}

.controller {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px 0;
    align-items: center;
}

.suggestionItem {
    padding: 16px 0;
    display: flex;
    align-items: center;
    border-bottom: #ededed 1px solid;
    cursor: pointer;

}

.suggestionItem:hover {
    background: #ededed;
}

@media (max-width: 768px) {
    .addressInput > input {
        font-size: 18px;
        font-family: 'HelveticaNeueCyr-Medium', sans-serif;
    }
}

.emailAddress {
    display: flex;
    margin-bottom: 45px;
    align-items: center;
}

@media (max-width: 768px) {
    .emailAddress {
        display: inline-block;
        margin-bottom: 22px;
    }
}

@media (max-width: 420px) {
    .emailAddress {
        max-width: 335px;
    }
}

.email {
    margin-right: 25px;
}

@media (max-width: 768px) {
    .email {
        margin-bottom: 25px;
    }
}

@media (max-width: 768px) {
    .delivery {
        margin-bottom: 15px;
        background: #fff;
    }
}

.mailing {
    position: relative;
    height: 28px;
    cursor: pointer;
    min-width: 205px;
    display: flex;
    align-items: center;
}

.address {
    display: flex;
    margin-bottom: 20px;
    align-items: center;
    cursor: pointer;
    position: relative;
}

.qualification {
    position: absolute;
    bottom: -30px;
    right: 0;
    width: 130px;
}

@media (max-width: 768px) {
    .address {
        margin-bottom: 11px;
    }
}

.deliveryTime {
    display: flex;
    margin-bottom: 45px;
}

@media (max-width: 768px) {
    .deliveryTime {
        margin-bottom: 35px;
    }
}

.payment, .selectedPayment, .changePayment {
    display: flex;
    align-items: center;
}

.selectedPayment {
    cursor: pointer;
}

.changePayment {
    cursor: pointer;
}

.payment {
    margin-bottom: 50px;
}

@media (max-width: 768px) {
    .payment {
        margin-bottom: 0;
    }
}

.payButton {
    max-width: 302px;
    max-height: 50px;
}

@media (max-width: 768px) {
    .payButton {
        height: 100px;
        width: 375px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 35px;
    }
}


.promoCard {
    background: #fff;
    padding: 25px 20px 30px 20px;
    margin-bottom: 45px;
    max-height: 191px;
    max-width: 768px;
}

@media (max-width: 768px) {
    .promoCard {
        margin-bottom: 15px;
    }
}

.cards {
    display: flex;
}

.intervalsList {
    display: grid;
    grid-template-columns: repeat(2, min-content);
    grid-gap: 8px 16px;
    flex-wrap: wrap;
    margin-bottom: 24px;
}
@media (max-width: 1170px) {
    .cards {
        width: 375px;
        display: inline-block;
    }
}

.priceCard {
    background: #fff;
    padding: 40px 20px;
    max-width: 768px;
}

@media (max-width: 768px) {
    .priceCard {
        padding: 40px 20px;
        margin-bottom: 15px;
    }
}

.priceCard > div {
    display: flex;
    justify-content: space-between;
    padding-bottom: 8px;
}



.whatsappContact {
    margin-bottom: 10px;
    line-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 768px) {
    .whatsappContact {
        margin-bottom: 7px;
        line-height: 16px;
    }
}

.modalCard {
    width: 260px;
    height: 78px;
    background: #EDEDED;
    margin-bottom: 18px;
    border-radius: 8px;
    padding: 8px 12px 0;
}

.modalCardIcon {
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
}

.modalCardIcon  img {
    width: 18px;
    height: 18px;
}

.modalCardActive {
    background: #fff;
    width: 260px;
    height: 78px;
    margin-bottom: 18px;
    border-radius: 8px;
    padding: 8px 12px 0;
    box-shadow: 0 1px 12px rgba(0, 0, 0, 0.15);
}

.addressCard {
    width: 87px;
    height: 50px;
    border: 0.5px solid #909090;
    border-radius: 3px;
    padding: 8px 0 5px 8px;
    margin-right: 7px;
    margin-bottom: 9px;
    font-family: 'HelveticaNeueCyr-Medium', sans-serif;
    cursor: pointer;
}

.addressCard:last-child {
    margin-right: 0;
}

.commentCard {
    width: 275px;
    border: 0.5px solid #909090;
    border-radius: 3px;
    padding: 8px 8px 5px 8px;
    margin-right: 7px;
    margin-bottom: 42px;
    cursor: pointer;
}

.commentInput {
    resize: none;
    width: 100%;
    font-family: 'HelveticaNeueCyr-Medium', sans-serif;
    cursor: pointer;
}

.deliveryDetails {
    margin-left: 50px;
    margin-top: 25px;
}

@media (max-width: 768px) {
    .deliveryDetails {
        margin-left: 30px;
        margin-bottom: 50px;
    }
}

.timeButton {
    cursor: pointer;
    width: 134px;
    height: 40px;
    border-radius: 20px;
    background: #213140;
    display: flex;
    justify-content: center;
    align-items: center;
}

.selectDeliveryTime {
    display: flex;
    align-items: center;
    margin-left: -5px;
    gap: 13px;
    margin-bottom: 14px;
}

@media (max-width: 768px) {
    .selectDeliveryTime {
        margin-bottom: 22px;
    }
}

.paymentBlock {
    background: #fff;
    padding: 18px;
    margin-bottom: 15px;
}
